.mobile-thank-you-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem 0rem 2rem;
  margin: auto;
  max-width: 400px;

  .mobile-thank-you-fareshare-logo {
    svg {
      height: 86px;
      width: 237px;
    }
  }

  .mobile-thank-you-title {
    font-style: normal;
    font-weight: 600;
    font-size: 25.1179px;
    line-height: 30px;
    text-align: center;
    margin-top: 2rem;
  }

  .mobile-thank-you-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.01em;
    text-align: center;

    margin-top: 1.2rem;
  }

  .mobile-thank-you-follow-us {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
    margin-top: 2rem;
  }

  .mobile-thank-you-icons-group {
    display: flex;
    justify-content: center;
    column-gap: 11px;
    margin-top: 1rem;

    svg {
      width: 35px;
      height: 35px;
    }
  }
  .mobile-thank-you-fareshare-url {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #7cc242;

    margin-top: 2rem;
  }
}
