@import "../../assets/sass/variables";

.public-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  .form-group {
    margin: 0 0 1rem;
    color: #3a3541;
  }

  .content-box {
    display: flex;
    position: relative;
    bottom: 3.0375rem;
    flex-direction: column;
    width: 29.2rem;
    margin: 1rem;
    border-radius: 0.375rem;
    padding: 1.5rem 2rem 0px 2rem;
    background: white;
  }

  .content-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.3125rem;

    img {
      width: 15.25rem;
      height: 5.5625rem;
    }
  }

  .content-body {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    > * {
      width: 100%;
    }

    h2 {
      position: relative;
      bottom: 0.25rem;
      padding: 3rem, 1.75px, 2.25rem, 1.75rem;
      font-size: 1.25rem;
      line-height: 2rem;
      letter-spacing: 0.009375rem;
    }
  }
}
