@import "assets/sass/styles";
@import "antd/dist/antd.css";
//@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("assets/fonts/frutiger/stylesheet.css");

$error-color: #ea5455;

html {
  body {
    font-family: "frutigerregular";
    .ant-btn,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "frutigerregular";
    }
  }
}
.input-error {
  border-color: $error-color !important;
  background-color: #ea54551a !important;
}

.scrollbar-track {
  width: 4px !important;
  .scrollbar-thumb {
    background-color: #7cc242;
    width: 4px;
  }
}
.ant-input-clear-icon {
  svg {
    color: #7cc343;
  }
}

.radio-btn-input-error {
  span {
    color: $error-color !important;
  }
  .ant-radio-wrapper .ant-radio .ant-radio-inner {
    border-color: $error-color !important;
  }
}

.disabled-btn {
  cursor: not-allowed;
}
.error {
  color: $error-color;
  font-size: 12px;
  margin-top: 0.25rem;
}

.ant-input-number-input {
  padding: 0;
}

.bold-text {
  font-weight: bold;
}

.ant-btn {
  margin-right: 10px;
  min-width: 80px;
}

.ant-switch {
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0 !important;
  &.ant-switch-checked {
    &.disableSwitch {
      // background-color: grey !important;
      opacity: 40%;
      pointer-events: none;
    }

    background-color: #7cc343 !important;
    border: none;
    mix-blend-mode: normal;
    .ant-switch-handle::before {
      background-color: white;
    }
  }
  $width: 2.3rem;
  $height: $width / 2;
  background: #e4e4e4;
  margin-left: 5px;
  width: $width;
  min-width: $width;
  height: $height;
  margin: auto 0.35714rem !important;
  .ant-switch-handle {
    width: $height/1.3;
    height: $height/1.3;
    &::before {
      background-color: #7cc343;
    }
  }
}

.ant-btn.ant-btn-circle {
  height: 37px;
  width: 37px;
}

.company-logo {
  display: flex;
  height: 40px;
  font-size: 18px;
}

.company-logo .logo {
  width: 40px;
  float: left;
  margin-right: 2%;
}

.success-box {
  margin-bottom: 5px;
  background: mediumseagreen;
  padding: 9px;
  color: white;
  // text-align: center;
  border-radius: 3px;
}

.menu-simple-default {
  color: black !important;
}

.menu-simple-default:hover {
  color: #336cfb !important;
}

.menu-simple-selected {
  color: #336cfb !important;
}

.layout.vertical .app-container .footer {
  left: 0 !important;
}

.ant-tooltip-placement-bottom {
  top: 3.96rem !important;
}

.ant-tooltip-placement-right {
  left: 16.3rem !important;
}

@media screen and (max-width: 990px) {
  .reports-bottom-section {
    // width: 125% !important;
    padding-right: 6px !important;
  }
}

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}
.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.ant-collapse-content {
  line-height: 3 !important;
  max-height: 80px !important;
  overflow-y: auto !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.require-ant-select {
  .ant-select-selector {
    border-color: $error-color !important;
    background-color: #ea54551a !important;
  }
}

.filtersMenuDropdown {
  padding: 1.5rem 1rem;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
  border: 1px solid #f0f0f0;
}

//////////////////////////////////////Listing Tabs////////////////////////////////////////////

.ant-tabs {
  font-size: 13px;
  width: 100%;
  .ant-tabs-content-holder {
    //    margin-top: 10px;
  }
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      justify-content: center;
    }
    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-tab {
      border: 1px solid $primary-bg-color;
      background: $default-bg-color;
      padding: 10px;
      // width: 162px;
      box-sizing: border-box;
      font-size: 12px;
      color: $primary-bg-color;
      // border-radius: 21px;
      text-align: center;
      display: flex;
      justify-content: center;
      max-height: 33px;
      font-size: 14px;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:not(:first-child) {
        margin-left: 0px !important;
        border-radius: 0px 4px 4px 0px;
      }

      .ant-tabs-tab-btn {
        color: unset;
      }

      // &:hover,
      &.ant-tabs-tab-active {
        background-color: $primary-bg-color;
        color: $primary-txt-color;
        border: 1px solid $primary-bg-color;
      }
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: unset;
      }
    }
    .ant-tabs-ink-bar {
      &.ant-tabs-ink-bar-animated {
        left: unset !important;
        width: unset !important;
      }
    }
  }
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-bottom {
  margin: 10px 0px 0px 0px !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: unset;
}

//////////////////////////////////////Listing Tabs////////////////////////////////////////////

.cursor-pointer {
  cursor: pointer;
}

/* Styles For Historical Task Table */

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.indicator.online {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  animation: pulse-animation 2s infinite linear;
}

@keyframes pulse-animation {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
  }
  75% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

.loading-animation-wrapper {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  z-index: 1000000;

  .loading-animation {
    position: absolute;
    // left: 50%;
    // top: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    height: 50px;
    width: 50px;
  }
}

.box {
  width: 180px;
  height: auto;
  background-color: #ffbf00;
  opacity: 0.5;
  padding: 11px;
  position: relative;
  display: inline;
}

.arrow-right:after {
  content: " ";
  position: absolute;
  right: 19px;
  top: -5px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid grey;
  border-bottom: 15px solid transparent;
}

// .recharts-legend-wrapper {
//   margin-bottom: -20px !important;
// }

.custom-span {
  margin: 0 ($module-rem / 2) !important;
}

.customRangePicker {
  margin-right: -20px;
  .ant-picker-range {
    font-weight: bold;
    width: 89%;
    height: 33px;
    font-size: 12px;
  }
  .ant-picker-input > input {
    font-size: 12px;
  }
}

.ant-picker-range {
  border-radius: 6px;
  background-color: $default-bg-color;
  border: 1px solid #ebebeb;
  height: 36.5px;
  width: 100%;
}

.ant-picker.ant-picker-disabled {
  border: unset;
  color: rgba(0, 0, 0, 0.25);
}

.filterDropdown {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 33px !important;
    font-size: 12px;
    background-color: $default-bg-color !important;
  }
  .ant-select.mb-lg-0.mb-4.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    margin-bottom: unset !important;
  }
  p.error {
    display: none;
  }
}

.ant-btn {
  padding: 1rem 0.7rem !important;
}

.ant-input-affix-wrapper:not(textarea) {
  height: 44.5px !important;
}

.ant-input:not(textarea) {
  height: 42px !important;
}

.invite-link-form-group {
  .ant-input-affix-wrapper {
    background-color: #f3f3f4;
    border: 1px solid #d2d1d3;
    input {
      color: rgba(23, 24, 50, 0.6);
      font-weight: bold;
    }

    .ant-input-suffix {
      padding-left: 0.8rem;
    }

    .ant-input:not(:last-child) {
      padding-right: 0rem !important;
    }
  }
}

.custom-popover-text {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0625rem;
  text-decoration-line: underline;
  color: $secondary-color-blue;
  &:hover {
    text-decoration-line: underline;
    color: $secondary-color-blue;
  }
}

.overflow-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.customHeader {
  padding-top: 1rem;
  //position: sticky;
  top: 0;
  z-index: 1;
  // background: $bg-color;
}

.ant-table-column-title {
  z-index: 0;
}

// @media screen {
//   .col-lg-3,
//   .col-lg-6,
//   .col-md-3,
//   .col-3,
//   .col-md-6 {
//     @media #{$max767} {
//       min-width: -webkit-fill-available;
//     }

//     @media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
//       min-width: -webkit-fill-available;
//     }
//   }
// }

.customTable {
  @media only screen and (min-height: #{$minTabletHeight}) and (max-width: #{$maxTabletWidth}) {
    margin-left: 10%;
    margin-right: 10%;
    max-width: -webkit-fill-available;
  }
}

.ant-modal-header {
  padding: 0 !important;
  background: transparent;
}

.ant-modal .ant-modal-header .title {
  padding: 1.85rem 1.93rem 0.1rem !important;
  margin: 0;
  width: 95%;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: transparent;
}

.ant-tooltip,
.ant-select {
  font-size: 13px;
}

.ant-modal-mask {
  background-color: rgba(#000, 0.7);
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal .ant-modal-body {
  padding: 1.5rem 3.5rem; //1.5rem 3.5rem 0.05rem 3.5rem; //1.5rem 3.5rem; // 1.5rem;
  font-size: 13px;
}

.ant-modal-close {
  top: 16px !important;
  right: 16px;
  border-radius: 33px;
  background: $primary-bg-color;
  color: $primary-txt-color;
  &:hover {
    background: $primary-bg-color;
    color: $primary-txt-color;
  }
}

.ant-modal-close-x {
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-button-list {
  .ant-btn {
    margin-right: unset;
  }
}

.col-half-offset {
  margin-right: 3.166666667%;
}

.fixed-footer-wrapper {
  position: fixed;
  bottom: 0;
  right: 10px;
  left: $vertical-navbar-width-minified;
  background: $bg-color;
  .footer-container {
    display: flex;
    padding: 10px 0 10px 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.ant-pagination-options-quick-jumper {
  display: inline-flex;
  height: 40px;
  line-height: 17px;
  align-items: center;
}

.ant-breadcrumb {
  font-weight: bolder;
  font-size: 10px;
  margin-right: 10px;
  > span:last-child {
    // color: #00000050;
    color: #00000099;
  }
  a {
    color: black;
  }
}

.bold-content {
  text-shadow: 0px 0px, 0px 0;
  margin-right: 5px;
}

.ant-btn.ant-btn-primary:not(:disabled) {
  background: $primary-bg-color;
  color: white;

  @media (hover: hover) {
    &:hover {
      border: 1px solid $primary-bg-color;
      box-sizing: border-box;
      background-color: white;
      color: $primary-bg-color;
      text-shadow: none;
    }
  }
  @media (hover: none) {
    //mobile devices
    &:active {
      border: 1px solid $primary-bg-color;
      box-sizing: border-box;
      background-color: white;
      color: $primary-bg-color;
      text-shadow: none;
    }
  }

  @media screen and (max-width: 420px),
    (max-height: 420px) and (orientation: landscape) {
    margin: 0;
  }
}

.add-new-btn {
  margin-right: 0px;
}

////////////////////input-field/-custom-lable//////////////
.custom-label {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 0.75rem;
  letter-spacing: 0.009375rem;
  display: inline;
  position: relative;
  top: 0.6875rem;
  left: 0.8125rem;
  padding: 0px 7px 0px 7px;
  background-color: white;
  z-index: 1000;
  color: black;
}
///////////////////////////////////////

html .ant-input-affix-wrapper {
  background-color: white;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search,
.ant-select-multiple .ant-select-selector .ant-select-selection-search,
.ant-select .ant-select-selector .ant-select-selection-search {
  left: calc(1.53846rem - 2px);
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  left: 1.53846rem;
  right: 1.92308rem;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector > *,
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-input-suffix,
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-input-prefix,
.ant-select-multiple .ant-select-selector > *,
.ant-select-multiple .ant-select-selector .ant-input-suffix,
.ant-select-multiple .ant-select-selector .ant-input-prefix,
.ant-select .ant-select-selector > *,
.ant-select .ant-select-selector .ant-input-suffix,
.ant-select .ant-select-selector .ant-input-prefix {
  align-items: center;
  display: flex;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
* {
  outline: none !important;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector,
.ant-select .ant-select-selector {
  background-color: white;
}

.ant-btn.ant-btn-default {
  border: 1px solid $primary-bg-color;
  box-sizing: border-box;
  background-color: white;
  color: $primary-bg-color;
  svg {
    fill: $primary-bg-color;
  }
  &:hover,
  &:focus,
  &:active {
    background: white;
    color: $primary-color-grey;
    border: 1px solid $primary-color-grey;
    svg {
      fill: #6e6b7b;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // border: 1px solid #ebebeb !important;
  height: 42px !important;
}

html .ant-input,
.ant-input:not(textarea) {
  background: white;
}

.ant-table-wrapper {
  &:not(.trackedItemListing) .ant-table-tbody > tr > td {
    border-top: 6px solid white;
  }
}

.ant-checkbox-wrapper {
  line-height: unset;
  align-items: center;

  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox-inner:after {
    height: 11px;
    width: 5px;
  }
  &:not(.ant-checkbox-wrapper-checked):hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $primary-bg-color;
        &:after {
          border-color: $primary-bg-color;
        }
      }
    }
  }
}

.ant-checkbox-inner::after {
  top: 46%;
  left: 21%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-bg-color;
  border-color: $primary-bg-color !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white;
}

//////////////////////////////////////Forms////////////////////////////////////////////

.ant-select-item-option-content {
  font-size: 12px;
}

.ant-picker-dropdown {
  font-size: 12px;
}

#my-form {
  margin-top: 15px;
}

html .ant-input::placeholder,
.ant-input-prefix {
  font-size: 14px;
  font-weight: 400;
  color: rgba(58, 53, 65, 0.68) !important;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-multiple .ant-select-selector .ant-select-selection-placeholder,
.ant-select-multiple .ant-select-selector .ant-select-selection-item,
.ant-select .ant-select-selector .ant-select-selection-placeholder,
.ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 14px;
  color: rgba(58, 53, 65, 0.68) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: #ebebeb 1px solid;
  border-radius: 7px;
}

//////////////////////////////////////Forms////////////////////////////////////////////

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  border: 1px ridge;
  &:hover {
    background: #f1f1f1;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* Handle on hover */
  background: #7cc242;
  &:hover {
    background: #7cc242;
  }
}

.ant-form-item-label > label::after {
  content: unset;
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.fareshare-hyperlink {
  color: #7cc242;

  &:hover {
    font-weight: bolder;
    color: #7cc242;
  }
}
